import React, { useRef, useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Send, Check, Mail, User, MessageSquare, Loader2, X, AlertCircle } from "lucide-react";
import emailjs from "@emailjs/browser";

function Contact () {
  const form = useRef();
  const [sent, setSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [errors, setErrors] = useState({});

  console.log(process.env.REACT_APP_PUBLIC_KEY);
  useEffect(() => {
    setSent(false);
    setShowFeedback(false);
  }, []);

  useEffect(() => {
    emailjs.init(process.env.REACT_APP_PUBLIC_KEY);
  }, []);

  // Validation functions
  const validateName = (name) => {
    return /^[A-Za-z\s-]+$/.test(name);
  };

  const validateEmail = (email) => {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
  };

  const validateForm = (formData) => {
    const newErrors = {};

    if (!validateName(formData.first_name.value)) {
      newErrors.first_name = "First name should only contain letters";
    }

    if (!validateName(formData.last_name.value)) {
      newErrors.last_name = "Last name should only contain letters";
    }

    if (!validateEmail(formData.user_email.value)) {
      newErrors.email = "Please enter a valid email address";
    }

    if (formData.message.value.trim().length < 10) {
      newErrors.message = "Message should be at least 10 characters long";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  function sendEmail(e) {
    e.preventDefault();
    
    if (!validateForm(e.target)) {
      return;
    }

    setLoading(true);

    const templateParams = {
      from_name: `${e.target.first_name.value} ${e.target.last_name.value}`,
      from_email: e.target.user_email.value,
      message: e.target.message.value,
      first_name: e.target.first_name.value,
      last_name: e.target.last_name.value,
    };

    emailjs
      .send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
          setLoading(false);
          setSent(true);
          setShowFeedback(true);
          setErrors({});
          form.current.reset();
          setTimeout(() => {
            setSent(false);
          }, 3000);
        },
        (error) => {
          console.log(error.text);
          setLoading(false);
        }
      );
  }

  const InputError = ({ error }) => {
    if (!error) return null;
    
    return (
      <motion.div
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        className="flex items-center gap-1 mt-1 text-red-500 text-sm"
      >
        <AlertCircle className="w-4 h-4" />
        <span>{error}</span>
      </motion.div>
    );
  };

  return (
    <div
      id="contact"
      className="relative bg-gradient-to-b from-theme-200 to-theme-300 py-20 px-4"
    >
      <div className="max-w-4xl mx-auto relative">
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-16"
        >
          <h1 className="text-6xl font-bold text-main-black mb-4">
            Get In Touch
          </h1>
          <div className="h-1 w-24 bg-gradient-to-r from-theme-400 to-theme-600 mx-auto rounded-full"></div>
        </motion.div>

        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="bg-white/60 backdrop-blur-lg rounded-2xl p-8 shadow-lg"
        >
          <AnimatePresence>
            {showFeedback && (
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="mb-6 bg-green-50 border border-green-200 rounded-lg p-4 relative"
              >
                <button
                  onClick={() => setShowFeedback(false)}
                  className="absolute right-2 top-2 text-green-600 hover:text-green-800"
                >
                  <X className="w-4 h-4" />
                </button>
                <div className="flex items-start">
                  <Check className="w-5 h-5 text-green-600 mt-0.5 mr-3" />
                  <div>
                    <h3 className="text-green-800 font-medium mb-1">
                      Message Sent Successfully!
                    </h3>
                    <p className="text-green-700">
                      Thank you for reaching out. I will get back to you as soon as possible!
                    </p>
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>

          <form ref={form} onSubmit={sendEmail} className="space-y-6">
            <div className="grid md:grid-cols-2 gap-6">
              {/* First Name */}
              <div className="relative">
                <label className="block text-sm font-medium text-main-black mb-2">
                  First Name
                </label>
                <div className="relative">
                  <User className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                  <input
                    required
                    name="first_name"
                    type="text"
                    className={`w-full pl-10 pr-4 py-3 bg-white/50 border rounded-lg focus:outline-none transition-colors
                      ${errors.first_name ? 'border-red-500 focus:border-red-500' : 'border-theme-200 focus:border-theme-500'}`}
                    placeholder="First name here..."
                  />
                </div>
                <InputError error={errors.first_name} />
              </div>

              {/* Last Name */}
              <div className="relative">
                <label className="block text-sm font-medium text-main-black mb-2">
                  Last Name
                </label>
                <div className="relative">
                  <User className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                  <input
                    required
                    name="last_name"
                    type="text"
                    className={`w-full pl-10 pr-4 py-3 bg-white/50 border rounded-lg focus:outline-none transition-colors
                      ${errors.last_name ? 'border-red-500 focus:border-red-500' : 'border-theme-200 focus:border-theme-500'}`}
                    placeholder="Last name here..."
                  />
                </div>
                <InputError error={errors.last_name} />
              </div>
            </div>

            {/* Email */}
            <div className="relative">
              <label className="block text-sm font-medium text-main-black mb-2">
                Email
              </label>
              <div className="relative">
                <Mail className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                <input
                  required
                  name="user_email"
                  type="email"
                  className={`w-full pl-10 pr-4 py-3 bg-white/50 border rounded-lg focus:outline-none transition-colors
                    ${errors.email ? 'border-red-500 focus:border-red-500' : 'border-theme-200 focus:border-theme-500'}`}
                  placeholder="Email here..."
                />
              </div>
              <InputError error={errors.email} />
            </div>

            {/* Message */}
            <div className="relative">
              <label className="block text-sm font-medium text-main-black mb-2">
                Message
              </label>
              <div className="relative">
                <MessageSquare className="absolute left-3 top-3 w-5 h-5 text-gray-400" />
                <textarea
                  required
                  name="message"
                  rows="4"
                  className={`w-full pl-10 pr-4 py-3 bg-white/50 border rounded-lg focus:outline-none transition-colors
                    ${errors.message ? 'border-red-500 focus:border-red-500' : 'border-theme-200 focus:border-theme-500'}`}
                  placeholder="Your message here..."
                />
              </div>
              <InputError error={errors.message} />
            </div>

            {/* Submit Button */}
            <div className="flex justify-center">
              <button
                type="submit"
                disabled={loading || sent}
                className={`
                  flex items-center gap-2 px-8 py-3 rounded-full text-white font-medium
                  transition-all duration-300 transform hover:-translate-y-1
                  ${loading ? "bg-gray-400 cursor-not-allowed" : 
                    sent ? "bg-green-500 hover:bg-green-600" : 
                    "bg-theme-600 hover:bg-theme-700"}
                `}
              >
                {loading ? (
                  <>
                    <Loader2 className="w-5 h-5 animate-spin" />
                    Sending...
                  </>
                ) : sent ? (
                  <>
                    <Check className="w-5 h-5" />
                    Sent!
                  </>
                ) : (
                  <>
                    <Send className="w-5 h-5" />
                    Send Message
                  </>
                )}
              </button>
            </div>
          </form>
        </motion.div>
      </div>
    </div>
  );
};

export default Contact;
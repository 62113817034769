import React, { useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { Github, Eye, ExternalLink } from "lucide-react";
import data from "../assets/projects.json";
import "./style.css"

const Projects = () => {
  const containerRef = useRef(null);
  const scrollRef = useRef(null);

  useEffect(() => {
    const element = scrollRef.current;
    
    const handleWheel = (e) => {
      if (element) {
        e.preventDefault();
        element.scrollLeft += e.deltaY;
      }
    };

    element?.addEventListener('wheel', handleWheel, { passive: false });
    
    return () => {
      element?.removeEventListener('wheel', handleWheel);
    };
  }, []);

  return (
    <div id="project" className="relative bg-gradient-to-b from-theme-100 to-theme-200 py-20 overflow-hidden">
          <div className="absolute inset-0 opacity-20">
        <div className="absolute top-20 -left-4 w-64 h- bg-theme-400 rounded-full mix-blend-multiply filter blur-xl animate-blob"></div>
        <div className="absolute top-10 -right-4 w-72 h-72 bg-theme-500 rounded-full mix-blend-multiply filter blur-xl animate-blob animation-delay-2000"></div>
        <div className="absolute top-64 left-20 w-72 h-72 bg-theme-600 rounded-full mix-blend-multiply filter blur-xl animate-blob animation-delay-4000"></div>
      </div>
      {/* Animated Background Elements */}
      <div className="absolute inset-0" aria-hidden="true">
        {/* Floating circles */}
        <motion.div
          className="absolute w-40 h-40 rounded-full bg-theme-300/30 blur-2xl"
          animate={{
            x: [0, 100, 0],
            y: [0, -50, 0],
            scale: [1, 1.2, 1],
          }}
          transition={{
            duration: 15,
            repeat: Infinity,
            ease: "linear"
          }}
          style={{ left: '10%', top: '20%' }}
        />
        <motion.div
          className="absolute w-32 h-32 rounded-full bg-theme-400/20 blur-2xl"
          animate={{
            x: [0, -70, 0],
            y: [0, 60, 0],
            scale: [1, 1.1, 1],
          }}
          transition={{
            duration: 18,
            repeat: Infinity,
            ease: "linear"
          }}
          style={{ right: '15%', top: '30%' }}
        />
        <motion.div
          className="absolute w-48 h-48 rounded-full bg-theme-500/20 blur-2xl"
          animate={{
            x: [-50, 50, -50],
            y: [60, 0, 60],
            scale: [1, 1.3, 1],
          }}
          transition={{
            duration: 20,
            repeat: Infinity,
            ease: "linear"
          }}
          style={{ left: '50%', bottom: '20%' }}
        />

        {/* Grid pattern */}
        <div className="absolute inset-0 bg-grid-theme-700/[0.05] [mask-image:linear-gradient(to_bottom,transparent,black,transparent)]" />
      </div>

      <div className="relative">
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-16"
        >
          <h1 className="text-6xl font-bold text-main-black mb-4">Projects</h1>
          <div className="h-1 w-24 bg-gradient-to-r from-theme-400 to-theme-600 mx-auto rounded-full"></div>
        </motion.div>

        <div 
          ref={containerRef} 
          className="relative max-w-7xl mx-auto px-4"
        >
          <div 
            ref={scrollRef}
            className="flex overflow-x-auto hide-scrollbar gap-8 pb-8"
            style={{ scrollBehavior: 'smooth' }}
          >
            {data.Projects.map((project, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1 }}
                className="min-w-[400px] max-w-[400px]"
              >
                <div className="h-full bg-white/60 backdrop-blur-lg rounded-2xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 hover:-translate-y-1">
                  <div className="flex flex-col h-full">
                    <h3 className="text-xl font-bold text-main-black mb-3">
                      {project.Title}
                    </h3>

                    <p className="text-gray-700 mb-4 flex-grow">
                      {project.Description}
                    </p>

                    <div className="flex flex-wrap gap-2 mb-6">
                      {project.Tags.map((tag, tagIndex) => (
                        <span
                          key={tagIndex}
                          className="px-3 py-1 text-sm font-medium text-theme-700 bg-theme-100 rounded-full"
                        >
                          {tag}
                        </span>
                      ))}
                    </div>

                    <div className="flex gap-3">
                      <a
                        href={project.github}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex-1 flex items-center justify-center gap-2 bg-main-black text-white px-4 py-2 rounded-lg hover:bg-gray-800 transition-colors duration-300"
                      >
                        <Github className="w-5 h-5" />
                        <span>View Code</span>
                        <ExternalLink className="w-4 h-4 opacity-50" />
                      </a>

                      {project.live && (
                        <a
                          href={project.live}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex-1 flex items-center justify-center gap-2 bg-theme-600 text-white px-4 py-2 rounded-lg hover:bg-theme-700 transition-colors duration-300"
                        >
                          <Eye className="w-5 h-5" />
                          <span>Live Demo</span>
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>

          <div className="hidden md:block text-center mt-4 text-gray-500">
            <p>Scroll to explore more projects →</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;

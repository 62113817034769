import React, { useState } from "react";
import { motion } from "framer-motion";
import { Download, ChevronUp, ChevronDown } from "lucide-react";
import page1 from "../assets/Shubh Kakadia - Resume_page-0001.jpg";
import page2 from "../assets/Shubh Kakadia - Resume_page-0002.jpg";

const Resume = () => {
  const [activePage, setActivePage] = useState(1);

  const images = [
    { page: 1, src: page1 },
    { page: 2, src: page2 }
  ];

  return (
    <div className="relative bg-gradient-to-br from-theme-50 to-theme-200 py-20">
      {/* Animated Background */}
      <div className="absolute inset-0" aria-hidden="true">
        <motion.div
          className="absolute w-72 h-72 rounded-full bg-theme-300/20 blur-3xl"
          animate={{
            x: [0, 100, 0],
            y: [0, -50, 0],
          }}
          transition={{
            duration: 20,
            repeat: Infinity,
            ease: "linear"
          }}
          style={{ left: '10%', top: '20%' }}
        />
        <motion.div
          className="absolute w-96 h-96 rounded-full bg-theme-400/20 blur-3xl"
          animate={{
            x: [0, -70, 0],
            y: [0, 60, 0],
          }}
          transition={{
            duration: 25,
            repeat: Infinity,
            ease: "linear"
          }}
          style={{ right: '10%', bottom: '20%' }}
        />
      </div>

      <div className="max-w-6xl mx-auto px-4 relative">
        {/* Header */}
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-16"
        >
          <h1 className="text-6xl font-bold text-main-black mb-4">Resume</h1>
          <div className="h-1 w-24 bg-gradient-to-r from-theme-400 to-theme-600 mx-auto rounded-full"></div>
        </motion.div>

        {/* Resume Viewer */}
        <div className="flex flex-col items-center">
          {/* Navigation Buttons */}
          <div className="flex gap-4 mb-8">
            <button
              onClick={() => setActivePage(1)}
              className={`px-4 py-2 rounded-lg transition-all duration-300 ${
                activePage === 1 
                  ? 'bg-theme-600 text-white' 
                  : 'bg-white/60 text-main-black hover:bg-white/80'
              }`}
            >
              Page 1
            </button>
            <button
              onClick={() => setActivePage(2)}
              className={`px-4 py-2 rounded-lg transition-all duration-300 ${
                activePage === 2 
                  ? 'bg-theme-600 text-white' 
                  : 'bg-white/60 text-main-black hover:bg-white/80'
              }`}
            >
              Page 2
            </button>
          </div>

          {/* Resume Container */}
          <motion.div
            className="relative bg-white/60 backdrop-blur-lg p-6 rounded-2xl shadow-xl"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            {/* Page Navigation */}
            <div className="absolute left-1/2 transform -translate-x-1/2 -top-4 flex gap-2 bg-white/80 backdrop-blur-sm px-4 py-2 rounded-full shadow-lg">
              <button 
                onClick={() => setActivePage(prev => Math.max(prev - 1, 1))}
                disabled={activePage === 1}
                className="p-1 rounded-full hover:bg-theme-100 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <ChevronUp className="w-5 h-5" />
              </button>
              <span className="text-sm font-medium">Page {activePage} of 2</span>
              <button 
                onClick={() => setActivePage(prev => Math.min(prev + 1, 2))}
                disabled={activePage === 2}
                className="p-1 rounded-full hover:bg-theme-100 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <ChevronDown className="w-5 h-5" />
              </button>
            </div>

            {/* Resume Image */}
            <motion.img
              key={activePage}
              src={images.find(img => img.page === activePage).src}
              alt={`Resume page ${activePage}`}
              className="rounded-lg shadow-lg max-w-[700px] w-full"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
            />
          </motion.div>

          {/* Download Button */}
          <motion.a
            href="/Shubh Kakadia - Resume.pdf"
            download
            className="mt-8 flex items-center gap-2 bg-main-black text-white px-6 py-3 rounded-full hover:bg-gray-800 transition-all duration-300 transform hover:-translate-y-1"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <Download className="w-5 h-5" />
            <span>Download Resume</span>
          </motion.a>
        </div>
      </div>
    </div>
  );
};

export default Resume;
import React from "react";
import Navbar from "./components/Navbar";
import { Route, Routes } from "react-router-dom";
import Resume from "./components/Resume";
import "./components/style.css";
import Landing from "./components/Landing";
// import Overlay from "./components/Overlay";

function App() {
  return (
    <div className="app">
      <React.Fragment>

        <script
          src="https://kit.fontawesome.com/59166d771b.js"
          crossOrigin="anonymous"
        ></script>
        <script src="https://smtpjs.com/v3/smtp.js"></script>
        {/* <div className="progress"></div> */}

        {/* <Overlay/> */}
        <Navbar />
        <Routes>
          <Route path="/*" element={<Landing />} />
          {/* <Route path="/about" element={<About />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/contact" element={<Contact />} /> */}
          <Route path="/resume" element={<Resume />} />
        </Routes>
      </React.Fragment>
    </div>
  );
}

export default App;

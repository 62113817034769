import { useEffect, useState } from "react";
import "./style.css";
import shubhImg from "../assets/photo-removebg-preview.png";
import { FaLinkedin, FaGithub } from "react-icons/fa";
import Typewriter from "./Typewriter";
import About from "./About";
import Contact from "./Contact";
import Projects from "./Projects";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "./scrollToTop";

export default function Landing() {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const navigate = useNavigate();

  const openLinkedIn = () => {
    window.open("https://www.linkedin.com/in/shubh-kakadia/");
  };

  const openGitHub = () => {
    window.open("https://github.com/shubhkakadia");
  };

  function handlescrolling() {
    const scrollY = window.scrollY;
    setScrollPosition(scrollY);
    const mediaQuery = window.matchMedia("(max-width: 500px)");

    if (mediaQuery.matches) {
      if (scrollY < 650) navigate("/");
      if (scrollY > 650 && scrollY < 1300) navigate("/about");
      if (scrollY > 1300 && scrollY < 2200) navigate("/project");
      if (scrollY >= 2500) navigate("/contact");
    } else {
      if (scrollY < 650) navigate("/");
      if (scrollY > 650 && scrollY < 1300) navigate("/about");
      if (scrollY > 1300 && scrollY < 2200) navigate("/project");
      if (scrollY >= 2500) navigate("/contact");
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handlescrolling);
    return () => {
      window.removeEventListener("scroll", handlescrolling);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  // Add these styles to your CSS file
  const additionalStyles = `
    .profile-image {
      transition: transform 0.5s ease-in-out;
      animation: float 6s ease-in-out infinite;
    }

    .profile-image:hover {
      transform: scale(1.05);
    }

    @keyframes float {
      0% {
        transform: translateY(0px);
      }
      50% {
        transform: translateY(-20px);
      }
      100% {
        transform: translateY(0px);
      }
    }

    .social-icon {
      transition: all 0.3s ease;
      transform-origin: center;
    }

    .social-icon:hover {
      transform: scale(1.2) rotate(360deg);
    }

    .parallax-text {
      transform: translateY(${scrollPosition * 0.3}px);
      transition: transform 0.1s ease-out;
    }

    .fade-in {
      opacity: 0;
      animation: fadeIn 1s ease-in forwards;
    }

    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(20px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  `;

  return (
    <div id="home">
      <style>{additionalStyles}</style>
      
      <div className="namepage bg-main-black container animate-fade-in-up">
      
        <div className="block-1 flex justify-center items-center">
 
          <div className="fade-in" style={{ animationDelay: "0.3s" }}>
          <div className="text-[30px] justify-center items-center md:text-[50px] text-white flex gap-3">
            <h2 clasName="font-playwrite">Hello</h2>
            <h2 className="text-main-blue font-playwrite">I'm </h2>
          </div>
            <div>
              <Typewriter />
            </div>
            <div className="flex justify-center text-main-blue text-[20px] liner">
              Code, design, launch – the full-stack developer's journey.
            </div>
            <div className="flex justify-center pt-10">
              <FaLinkedin
                onClick={openLinkedIn}
                title="LinkedIn Profile"
                className="social-icon text-main-blue text-[35px] mr-5 ml-5 cursor-pointer icon"
              />
              <FaGithub
                onClick={openGitHub}
                title="GitHub Profile"
                className="social-icon text-main-blue text-[35px] mr-5 ml-5 cursor-pointer icon"
              />
            </div>
          </div>
        </div>
        <div className="block-2 flex justify-center items-center">
          <div className="fade-in" style={{ animationDelay: "0.6s" }}>
            <img
              src={shubhImg}
              alt=""
              className={`rounded-full picture profile-image ${
                isImageLoaded ? "opacity-100" : "opacity-0"
              }`}
              width="450"
              onLoad={() => setIsImageLoaded(true)}
            />
          </div>
        </div>
      </div>
      <ScrollToTop/>
      <About />
      <Projects />
      <Contact />
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { FaChevronUp } from 'react-icons/fa';

const ScrollToTop = () => {
  const [visible, setVisible] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 200) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    };

    toggleVisibility();
    window.addEventListener('scroll', toggleVisibility);
    
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const scrollToTop = () => {
    setIsAnimating(true);
    
    // Smooth scroll with easing
    const scrollStep = () => {
      const currentPosition = window.pageYOffset;
      if (currentPosition > 0) {
        window.scrollTo(0, currentPosition - currentPosition / 8);
        requestAnimationFrame(scrollStep);
      } else {
        setIsAnimating(false);
      }
    };
    
    requestAnimationFrame(scrollStep);
  };

  return (
    <>
      {visible && (
        <div className="fixed bottom-8 right-8 z-50">
          <button
            onClick={scrollToTop}
            className={`group relative flex items-center justify-center
              ${isAnimating ? 'animate-none' : ''}`}
            aria-label="Scroll to top"
          >
            {/* Decorative outer rings */}
            <div className="absolute w-14 h-14 rounded-full border border-main-blue/20 
              animate-[ping_3s_ease-in-out_infinite]
              group-hover:border-theme-300/20" />
            <div className="absolute w-12 h-12 rounded-full border border-main-blue/30 
              animate-[spin_8s_linear_infinite]
              group-hover:border-theme-300/30" />
            
            {/* Main button background with pulse */}
            <div className="absolute w-10 h-10 rounded-full bg-main-black/50 blur-sm
              animate-pulse group-hover:bg-theme-950/50" />

            {/* Main button */}
            <div className={`relative w-10 h-10 bg-main-black rounded-full 
              flex items-center justify-center
              border border-main-blue
              transition-all duration-300
              group-hover:bg-theme-950 group-hover:border-theme-300
              shadow-lg shadow-black/20
              ${isAnimating ? 'animate-[bounce_0.5s_ease-in-out_infinite]' : 'animate-none'}
              transform group-hover:scale-110`}
            >
              {/* Arrow icon with floating animation */}
              <FaChevronUp 
                className={`text-main-blue text-lg
                  transition-all duration-300
                  group-hover:text-theme-300
                  ${isAnimating ? 'animate-bounce' : 'animate-float'}`}
              />
            </div>

            {/* Animated tooltip */}
            <div className="absolute -top-12 left-1/2 -translate-x-1/2
              opacity-0 group-hover:opacity-100
              transform group-hover:-translate-y-1
              transition-all duration-300 ease-out
              pointer-events-none"
            >
              <span className="relative bg-main-black px-4 py-2 rounded-lg text-sm text-main-blue
                border border-main-blue/20 whitespace-nowrap
                after:content-[''] after:absolute after:top-full after:left-1/2
                after:-translate-x-1/2 after:border-8 after:border-transparent
                after:border-t-main-black">
                Back to top
              </span>
            </div>
          </button>
        </div>
      )}

      <style jsx>{`
        @keyframes float {
          0%, 100% { transform: translateY(0); }
          50% { transform: translateY(-3px); }
        }
        
        .animate-float {
          animation: float 2s ease-in-out infinite;
        }
      `}</style>
    </>
  );
};

export default ScrollToTop;
import React from "react";
import { motion } from "framer-motion";
import { 
  Layout, 
  Database, 
  Server,
  Terminal
} from "lucide-react";

const About = () => {
  const skillCategories = [
    {
      title: "Frontend Development",
      icon: Layout,
      skills: [
        "ReactJS",
        "TypeScript",
        "React-Redux",
        "HTML5/CSS3",
        "JavaScript (ES6+)"
      ]
    },
    {
      title: "Backend & API",
      icon: Server,
      skills: [
        "NodeJS",
        "ExpressJS",
        "REST API",
        "API Integration",
        "MVC Pattern"
      ]
    },
    {
      title: "Database & State",
      icon: Database,
      skills: [
        "MongoDB",
        "State Management",
        "Redux",
        "Data Flow",
        "API Integration"
      ]
    },
    {
      title: "Development Tools",
      icon: Terminal,
      skills: [
        "Git/GitHub",
        "AWS Hosting",
        "Npm/Yarn",
        "Agile/Scrum",
        "Jira"
      ]
    }
  ];

  return (
    <div id="about" className="min-h-screen bg-gradient-to-b from-white to-theme-100 text-main-black py-20 px-4 relative overflow-hidden">
      {/* Animated Background */}
      <div className="absolute inset-0 opacity-20">
        <div className="absolute top-0 -left-4 w-72 h-72 bg-theme-300 rounded-full mix-blend-multiply filter blur-xl animate-blob"></div>
        <div className="absolute top-0 -right-4 w-72 h-72 bg-theme-400 rounded-full mix-blend-multiply filter blur-xl animate-blob animation-delay-2000"></div>
        <div className="absolute top-64 left-20 w-72 h-72 bg-theme-500 rounded-full mix-blend-multiply filter blur-xl animate-blob animation-delay-4000"></div>
      </div>

      <div className="max-w-6xl mx-auto relative z-10">
        {/* Header Section */}
        <motion.div
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-16"
        >
          <h1 className="text-6xl font-bold text-main-black mb-4">
            About Me
          </h1>
          <div className="h-1 w-24 bg-gradient-to-r from-theme-400 to-theme-600 mx-auto rounded-full"></div>
        </motion.div>

        {/* Profile Summary */}
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="mb-16"
        >
          <div className="bg-white/60 backdrop-blur-lg rounded-2xl p-8 hover:bg-white/80 transition-all duration-300 shadow-lg">
            <p className="text-lg leading-relaxed text-main-black">
              As a dedicated React.js Developer with hands-on experience from MD Tech, I specialize in building modern web applications 
              using the MERN stack. My expertise spans across frontend development with React.js, state management with Redux, 
              and backend integration using Node.js and Express. Through my work on various projects, I've demonstrated proficiency in creating responsive, 
              user-friendly interfaces and implementing robust data management solutions. I'm passionate about clean code, 
              efficient state management, and creating seamless user experiences.
            </p>
          </div>
        </motion.div>

        {/* Skills Categories */}
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.4 }}
          className="grid md:grid-cols-2 lg:grid-cols-4 gap-6 mb-16"
        >
          {skillCategories.map((category, idx) => (
            <motion.div
              key={category.title}
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.6 + idx * 0.1 }}
              className="bg-white/60 backdrop-blur-lg rounded-xl p-6 hover:bg-white/80 transition-all duration-300 shadow-lg"
            >
              <div className="flex items-center mb-4">
                <category.icon className="w-6 h-6 text-theme-600 mr-3" />
                <h3 className="text-xl font-semibold text-main-black">{category.title}</h3>
              </div>
              <ul className="space-y-2">
                {category.skills.map((skill) => (
                  <li key={skill} className="text-main-black flex items-center">
                    <span className="w-2 h-2 bg-theme-500 rounded-full mr-2"></span>
                    {skill}
                  </li>
                ))}
              </ul>
            </motion.div>
          ))}
        </motion.div>

        {/* Stats Section */}
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.8 }}
          className="grid grid-cols-2 md:grid-cols-3 gap-8"
        >
          {[
            { label: "React Projects", value: "10+" },
            { label: "Technologies", value: "15+" },
            { label: "Years Experience", value: "3+" },
          ].map((stat, index) => (
            <div key={stat.label} className="text-center bg-white/60 backdrop-blur-lg rounded-xl p-6 hover:bg-white/80 transition-all duration-300 shadow-lg">
              <h3 className="text-4xl font-bold text-theme-600">
                {stat.value}
              </h3>
              <p className="text-main-black mt-2">{stat.label}</p>
            </div>
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default About;